(function ($) {
    "use strict";
    // var $whiteFirst = $('#main-header').hasClass('white');
    // $(window).on('scroll', function () {
    //     if (!$whiteFirst) {
    //         if ($(this).scrollTop() > 0) {
    //             $('#main-header').addClass('white');
    //             $('.logo img').attr('src', $('.logo img').data('white-src'));
    //         } else {
    //             $('.logo img').attr('src', $('.logo img').data('blue-src'));
    //             $('#main-header').removeClass('white');
    //         }
    //     }
    // });
    // Register modal steps
    $('ul.step-list li').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var _Step = $this.attr('data-step');
        if (_Step <= $('#registerModal form fieldset.active').attr('data-step')) {
            var fieldsetStep = $('#registerModal form fieldset[data-step="' + _Step + '"]');
            $('#registerModal form fieldset').hide();
            fieldsetStep.fadeIn();
            $('ul.step-list li').removeClass('active');
            $this.addClass('active');
        }
    });

    $('#registerModal input').on('focus', function () {
        $(this).parent().removeClass('input-error');
    });
    $('#registerModal').on('show.bs.modal', function () {
        $('#registerModal form fieldset:first-child').show().addClass('active');
        $('#registerModal .step-list li:first-child').addClass('active');
    });

    $('#registerModal').on('hide.bs.modal', function () {
        $('#registerModal form fieldset:not(:first-child)').hide();
        $('#registerModal form fieldset').removeClass('active');
        $('#registerModal .step-list li').removeClass('active');
        $('#registerModal input').val('');
    });

    $('#registerModal a.btn-blue').on('click', function (e) {
        e.preventDefault();
        var _filedSet = $(this).closest('fieldset');

        var next_step = true;
        _filedSet.find('input').each(function () {
            if ($(this).val() == "") {
                $(this).parent().addClass('has-error');
                next_step = false;
            }
        });

        if (next_step) {
            _filedSet.fadeOut(400, function () {
                $(this).attr('data-pass', true);
                $(this).next().fadeIn();
                $(this).removeClass('active');
                $(this).next().addClass('active');
                var _step = $(this).next().attr('data-step');
                $('ul.step-list li').removeClass('active');
                $('ul.step-list li[data-step=' + _step + ']').addClass('active');
            });
        }
    });

    $('#registerModal .link-default').on('click', function (e) {
        e.preventDefault();
        $('#registerModal').modal('toggle');
        $('#registerModal .loading').hide();
        $('#registerModal .success').hide();
        $('#registerModal .error').hide();
        $('#registerModal [class^="col-md"]').show();
    });

    $('#registerModal #registerModalInicio').on('click', function (e) {
        e.preventDefault();
        $('#registerModal .loading').hide();
        $('#registerModal .success').hide();
        $('#registerModal .error').hide();
        $('#registerModal [class^="col-md"]').show();
    });

    $('#registerModal form').on('submit', function (e) {
        var apiURL = window.apiURL || 'https://api.prop44.info';
        e.preventDefault();
        var isLastFieldset = $('#registerModal fieldset').eq(2).hasClass('active');
        if (!isLastFieldset) {
            $('#registerModal fieldset a.btn-blue').click();
            return false;
        }
        var Form = $(this);
        $('#registerModal [class^="col-md"] ,#registerModal .modal-header').fadeOut('fast');
        $('#registerModal .loading').show();
        $.ajax({
            type: "POST",
            url: apiURL + "/contacto/registro",
            data: Form.serialize()
        })
        .then(function () {
            $('#registerModal .loading').hide();
            $('#registerModal .success').fadeIn();
        })
        .catch(function (r) {
            $('#registerModal .loading').hide();
            if(r.responseJSON.errors) {
                var errores = Object.keys(r.responseJSON.errors).map(function (key) {
                    return key + ': ' + r.responseJSON.errors[key];
                })
                $('#registerModal .error p.message').html(errores.join('; '));
            }
            $('#registerModal .error').fadeIn();
        })
    });

    if (window.location.hash === '#registro') {
        setTimeout(function () {
            $('#registerModal').modal('toggle');
        }, 1000);
    }

    //Open mobile menu
    $('a.hamburger').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('open')) {
            $('body').css('overflow', 'auto')
        } else {
            $('body').css('overflow', 'hidden')
        }
        $(this).toggleClass('open');
        $('#main-header').toggleClass('menu-open');
    });
    var _inputs = $('input[type=text], textarea, input[type=search], input[type=email], input[type=tel]');

    _inputs.on('focus', function () {
        $(this).parent('.form-group').removeClass('is-valid');
        $(this).parent('.form-group').removeClass('has-error');
    });

    /*
    CONTACT FORM
     */
    $('#contactForm').on('submit', function (e) {
        var apiURL = window.apiURL || 'https://api.prop44.info';
        e.preventDefault();
        var Form = $(this);
        var _pass = true;
        $('#contactForm').find('input,textarea').each(function () {
            if ($(this).val() == "") {
                $(this).parent().addClass('has-error');
                _pass = false;
            }
        })
        if (_pass) {
            $.ajax({
                type: "POST",
                url: apiURL + "/contacto",
                data: Form.serialize(),
                success: function () {
                    $('#contactModal').find('.success').show();
                    $('#contactModal').find('.error').hide();
                    Form[0].reset();
                    $('#contactModal').modal()
                },
                error: function (error) {
                    $('#contactModal').find('.error').show();
                    $('#contactModal').find('.success').hide();
                    $('#contactModal').modal()
                }
            })
        }
    });

    //selects
    $('#city').select2({
        allowClear: false,
        closeOnSelect: true,
        width: 'resolve',
        language: {
            noResults: function () {
                return "Sin resultados";
            }
        }
    });
    $('#order').select2({
        allowClear: false,
        closeOnSelect: true,
        width: 'resolve',
        language: {
            noResults: function () {
                return "Sin resultados";
            }
        }
    });

    $("#neigh, #rent, #bedrooms, #ordenar-resultados, #rent-contact" +
        "#contacto-rent, #contacto-city, #contacto-bedrooms, #contacto-neigh,#prop-type").select2({
        allowClear: false,
        closeOnSelect: false,
        width: 'resolve',
        language: {
            noResults: function () {
                return "Sin resultados";
            }
        }
    });
    $('select').on('select2:unselect', function (evt) {
        if ($(this).val().length) {
            var _id = $(this).attr('id');
            var _el = $('.select2-selection--multiple.' + _id);
            _el.find('*').css('opacity', 0);
            setTimeout(function () {
                _el.addClass('active');
                _el.find('*').css('opacity', 1);
            }, 50);
        }
        var uldiv = $(this).siblings('span.select2').find('.select2-selection.select2-selection--multiple');
        badgeSelect(uldiv);

    });
    $('select').on('select2:select', function (evt) {
        var data = evt.params.data;

        var uldiv = $(this).siblings('span.select2').find('.select2-selection.select2-selection--multiple');
        badgeSelect(uldiv);

        $(this).next().find('.select2-selection').addClass('active');
        $(this).next().find('.select2-selection').children('.select2-selection__arrow').hide();
        $(this).next().find('.select2-selection__rendered').css('padding-right', '8px');

        var sel = $(this).attr('id');
        var val = $(this).val();

        $('#intro-result').show();
        $('.dinamic-result #' + sel + '-section').show();
        dinamicSearch(sel, val);

    });

    function badgeSelect(uldiv) {
        uldiv.find('.badge').remove();
        var count = uldiv.find('li').length - 1;
        if (count > 0) {
            uldiv.append("<div class='badge'>" + count + "</div>")
        }
    }

    /*
    *
    *
    *
    *
    *
    *
    *
    *
    *
    *
    *
    *
    * */
    // videos responsive
    $(".fit-vids").fitVids();

    // slider de detalle de propiedad

    $('.slider-detail').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        arrows: true,
        infinite: false,
        lazyLoad: 'ondemand'
    });
    $('.slider-detail').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.pagingInfo').text(i + ' de ' + slick.slideCount + ' fotografías');
    });


    // // On before slide change match active thumbnail to current slide
    // $('.slider-detail').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    //     var mySlideNumber = nextSlide;
    //     var current = currentSlide;
    //     $('.slider-nav-thumbnails .slick-slide').removeClass('slick-active');
    //     $('.slider-nav-thumbnails .slick-slide').eq(mySlideNumber).addClass('slick-active');
    //
    // });


    // panel de 'estás buscando..' del index

    function dinamicSearch(element, value) {
        var valArray = [];
        if (value.length > 1) {
            valArray.push(value);
            $('.dinamic-result #' + element + '-value').html(valArray)
        }
        else {
            $('.dinamic-result #' + element + '-value').html(value)
        }
    }

    $('select').on('select2:unselect', function (evt) {
        $(this).next().find('.select2-selection').removeClass('active');
        $(this).next().find('.select2-selection').children('.select2-selection__arrow').show();
        $(this).next().find('.select2-selection__rendered').css('padding-right', '24px');

        var sel = $(this).attr('id');

        if (sel !== 'prop-type') {
            $('.dinamic-result #' + sel + '-section').hide();
            dinamicSearch(sel, '');
        }

        $('.tooltip-prop').hide()

    });

    $('b[role="presentation"]').hide();

    // height cambiante en el select multiple

    $('.prop-type, .contacto-prop-type').on('change', function () {

        var values = $(this).val();
        if (values == "") {
            $(this).next().children().find('.select2-selection--multiple').css('height', '42px')
        }
        else {
            $(this).next().children().find('.select2-selection--multiple').css('height', 'inherit')
        }
        var sel = $(this).attr('id');
        $('.dinamic-result #' + sel + '-value').html(values);

    });

    // tooltip

    $('.prop-type').on('select2:selecting', function () {
        $('.tooltip-prop').show()
    });

    $('.prop-type').on('select2:close', function () {
        $('.tooltip-prop').hide()
    });

    $('.tooltip-prop').on('click', function () {
        $(this).hide()
    });

    // input range

    // var pricerange = $('.inputrange');

    // var rangeValue = [];
    // var minVal = 0;
    // var maxVal = 5000000;
    // var instance;
    // var $inputMin = $('input.range-min');
    // var $inputMax = $('input.range-max');
    // var dfrom, dto;
    // pricerange.ionRangeSlider({
    //     min: minVal,
    //     max: maxVal,
    //     type: 'double',
    //     force_edges: true,
    //     hide_min_max: true,
    //     prettify_separator: ".",
    //     prefix: '$ ',
    //     onStart: function (data) {
    //         $inputMin.prop("value", data.from);
    //         $inputMax.prop("value", data.to);
    //     },
    //     onFinish: function (data) {
    //         dfrom = data.fromNumber;
    //         dto = data.toNumber;
    //     },
    //     onChange: function (data) {
    //         $inputMin.prop("value", data.from);
    //         $inputMax.prop("value", data.to);
    //         dfrom = data.fromNumber;
    //         dto = data.toNumber;
    //     }

    // });
    // instance = pricerange.data("ionRangeSlider");
    // $inputMin.on("change keyup", function () {
    //     var val = $(this).prop("value");
    //     // validate
    //     if (val < minVal) {
    //         val = minVal;
    //     } else if (val > maxVal) {
    //         val = maxVal - 1;
    //     } else if (val >= $inputMax.val()) {
    //         val = $inputMax.val() - 1;
    //         // var val = $(this).prop("value", val);
    //     }

    //     instance.update({
    //         from: val
    //     });
    // });
    // $inputMin.on('focus', function () {
    //     $(this).val('')
    // });
    // $inputMax.on('focus', function () {
    //     $(this).val('')
    // });
    // $inputMax.on("change keyup", function () {
    //     var val = $(this).prop("value");
    //     // validate
    //     if (val < minVal) {
    //         val = minVal;
    //     } else if (val > maxVal) {
    //         val = maxVal - 1;
    //     }

    //     instance.update({
    //         to: val
    //     });
    // });

    //COLLAPSE FILTERS HOMEPAGE
    $('#filters-row').on('show.bs.collapse', function () {
        $('a[data-target="#filters-row"]').find('span').text('Filtros');
        $('a[data-target="#filters-row"]').find('i').attr('class', 'fa fa-angle-up');
        $('.refresh-prop.mobile').show();
        $('body,html').animate({scrollTop: $('.prop-search').offset().top})
        $('.removeFilters.home, .closeFilters.home').show()
    })
    $('#filters-row').on('hide.bs.collapse', function () {
        $('.refresh-prop.mobile').hide();
        $('a[data-target="#filters-row"]').find('span').text('Ver filtros');
        // $('a[data-target="#filters-row"]').find('i').attr('class', 'fa fa-angle-down')
        $('body,html').animate({scrollTop: $('.prop-search').offset().top})
        $('.removeFilters.home, .closeFilters.home').hide()
    })
    $('.closeFilters.home').on('click', function () {
        $('#filters-row').collapse('hide')
    })


    $('.select2-selection__arrow').append('<i class="fa fa-angle-down"></i>');


    // $('.removeFilters.home').on('click', function (e) {
    //     e.preventDefault();
    //     var f = $('#filters-row');
    //     f.find('input:not([type=number])').val('');
    //     f.find('input[type=number]').val(0);
    //     $('input[type=checkbox],input[type=radio]').prop('checked', false);
    //     $('.select2-container .badge').remove();
    //     $('.select2-selection').removeClass('active');
    //     f.find('select').each(function (i, v) {
    //         $(this).val("");
    //         $(this).val([]);
    //         $(this).trigger('change');
    //     });
    //     instance.update({
    //         from: minVal,
    //         to: maxVal
    //     });
    //     $inputMin.val(minVal);
    //     $inputMax.val(maxVal);
    // })

    // limpiar todos los campos con boton

    $('.refresh-prop').on('click', function () {

        // LIMPIAR CHECKBOX E INPUT RANGE.

        $('input[type="checkbox"]').prop('checked', false);

        // var slider = pricerange.data("ionRangeSlider");

        // slider.update({
        //     from: 0,
        //     to: 3800000
        // });

        $('.select2-selection').removeClass('active');
        $('.select2-selection__arrow').show();
        $('.prop-search').children().find("select").val(null).trigger("change");
        $('.tab-pane .active').children("select").val(null).trigger("change");

        // LIMPIAR BUSQUEDA DINAMICA

        $('#rent-value, #bedrooms-value, #city-value, #cochera-value, #currency-value, #neigh-value, #prop-type-value, #range-value-min, #range-value-max').html('');
        $('#bedrooms-section, #city-section, #cochera-section, #neigh-section, #prop-type-section, #rent-section, #range-value').hide();
        $('#intro-result').hide();

    });

    $('.prop-panel .btn-secondary').on('click', function () {
        //quitar panel de primer pedido
        $('.first-time').removeClass('active');
        $('.dejar-pedido').removeClass('active');
        $('.bell-icon i').removeClass('swing');
        //togglear entre estados activos
        $('.prop-panel .btn-secondary').removeClass('active');
        $(this).addClass('active')
    });

    //boton de buscar propiedades en panel
    var buscarprop = document.getElementById('buscar-prop');

    if (buscarprop) {

        $("#buscar-prop").on("click", function (event) {

            setInterval(function () {

                $('.dejar-pedido').toggleClass('active');
                $('.bell-icon i').toggleClass('swing');

            }, 500);

            $(this).off(event);

        });

        fillFields();

    }

    //realizar pedido personalizado

    function fillFields() {

        $('.dejar-pedido').on('click', function () {

            //quitar panel de primer pedido

            $('.first-time').removeClass('active');
            $('.dejar-pedido').removeClass('active');
            $('.bell-icon i').removeClass('swing');

            //mostrar panel de pedido personalizado

            $('.prop-panel').hide();
            $('.pedido-panel').height($('.prop-panel').height());
            $('.panel-rotate').addClass('active');
            $('.pedido-panel').addClass('active');

            // armar el pedido con los values de los selects

            $('.prop-values').empty();
            var ret = '';

            var btnactive = $('.toggle-rent button.active').text();

            ret += '<p class="text-uppercase">' + btnactive + '</p>';

            $(".tab-pane.active").find('.prop-type, #city, #neigh').each(function () {

                var idplace = $(this).attr('data-placeholder').split(" ").join("-").toLowerCase();

                if ($(this).val() != "") {
                    ret += '<p id="' + idplace + '">' + $(this).val() + '</p>';
                }
                else {
                    ret += '<p id="' + idplace + '">' + $(this).attr('data-placeholder') + ' sin especificar</p>';
                }

            });

            var cocheraCheck = $('#cochera').prop('checked');
            var cocheraValue = [];

            if (cocheraCheck == true) {
                cocheraValue.push('Con')
            }
            else {
                cocheraValue.push('Sin')
            }

            var currency = [];
            $('.currency-checkbox').find('input:checked').each(function () {
                currency = [];
                currency.push($(this).attr('id'));
            });

            ret += '<p>' + $('#bedrooms').val() + ' Dormitorios / ' + cocheraValue + ' cochera</p>';
            ret += '<p>MIN $' + rangeValue[0] + ' a ' + rangeValue[1] + ' MAX $ ' + '<span class="text-uppercase">' + currency + '</span></p>';

            $('.prop-values').append(ret);

            //tooltip

            $('.tooltip-panel').show()

        });
    }

    // cerrar pedido eprsonalizado

    $('.cerrar-pedido, .seguir-buscando').on('click', function () {

        $('.prop-values').empty();
        $('.panel-rotate').removeClass('active');
        $('.pedido-panel').removeClass('active');
        $('.prop-panel').show();
        $('.tooltip-panel').hide()

    });

    // icon share

    $('#main-share').on('click', function () {
        $(this).parent('.social-container').toggleClass('active');
        $(this).siblings('.share-icon').toggleClass('active');
    });

    // radiobutton de currency

    $('input[name=currency]').on('click', function () {
        var curren = $(this).next('label').text();
        $('#currency-value').html(curren)
    });

    // check de cochera

    $('#cochera').on('click', function () {
        $('#intro-result').show();
        $('#cochera-section').show();
        if ($(this).is(':checked')) {
            $('#cochera-value').html('con ')
        }
        else {
            $('#cochera-value').html('sin ')
        }
    });

    /* ------ Responsive menu ------ */
    $('.mobile-toggle').on('click', function () {
        $('.main-menu').toggleClass('open-menu');
    });

    /* ------ Hover Effect script ------ */

    $(".effect").on({
        mouseenter: function () {
            $(this).addClass('hover');
        },
        mouseleave: function () {
            $(this).removeClass('hover');
        }
    });


    /*    var activeTab = $('[href=' + location.hash + ']');
        activeTab && activeTab.tab('show');

        $('.toggle-rent button').removeClass('active');
        activeTab.addClass('active');

        $('.toggle-rent button').tab('hide');
        */

    /*    // Javascript to enable link to tab
     var hash = document.location.hash;
     console.log(hash);
     var prefix = "tab_";
     if (hash) {
     $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show');
     }

     // Change hash for page-reload
     $('.nav-tabs a').on('shown', function (e) {
     window.location.hash = e.target.hash.replace("#", "#" + prefix);
     });*/


    $('.dropdown-menu li a').on('click', function () {

        var pgurl = window.location.href.substr(window.location.href
            .lastIndexOf("#") + 1);
        var tabpart = $('#' + 'tab-' + pgurl);

        $('.toggle-rent li a').removeClass('active');
        $('.toggle-rent li a[href*="' + pgurl + '"]').addClass('active');
        $('.tab-pane').removeClass('active');
        $('.tab-pane').removeClass('in');
        tabpart.addClass('in active');

    });


    // ver mapa

    $('#vermapa').on('click', function () {
        $('#verlista').removeClass('active');
        $(this).addClass('active');
        $('#map-off').hide();
        $('#map-on').show()
    });

    $('#verlista').on('click', function () {
        $('#vermapa').removeClass('active');
        $(this).addClass('active');
        $('#map-on').hide();
        $('#map-off').show()
    });

    // anchor


    $('.anchor-animate').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000); // The number here represents the speed of the scroll in milliseconds
                return false;
            }
        }
    });

    // agregar clase activa a la navbar

    $(".navbar li").click(function () {
        $(".navbar li").removeClass('active');
        $(this).addClass("active");
    });

    // active page

    var url = window.location;
    // Will only work if string in href matches with location
    $('ul.nav a[href="' + url + '"]').parent().addClass('active');

    // Will also work for relative and absolute hrefs
    $('ul.nav a').filter(function () {
        return this.href == url;
    }).parent().addClass('active');

    // affix fix
    //
    // var panelWidth = $('.panel-rotate').width();
    //
    // $('.panel-rotate').on('affixed.bs.affix', function () {
    //     if ($(window).width() > 996) {
    //         $(this).width(panelWidth)
    //     }
    // });
})(jQuery);
